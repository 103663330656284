// @flow
import React from 'react';
import _find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import CombinedMetaModel from 'metaup/data/CombinedMetaModel';
import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
  DefaultFormFile, DefaultFormDropDown,
} from '../../elements/DefaultForm';

import type { User } from '../model/User.model';
import { userMetaModel } from '../model/User.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Franchisee } from '../../org/model/Franchisee.model';
import { analyseLoaded, announceFileIsUploaded } from '../../core/render/visualUtils';
import type { Office } from '../../org/model/Office.model';
import UnexpectedCaseException from '../../core/exceptions/UnexpectedCaseException';
import { authMetaModel } from '../../auth/model/Auth.model';

export type UserEditPageViewProps = {
  user: null | User | ErrorCapsule,
  role: 'cutter' | 'franchisee' | 'admin',
  franchisees: null | ErrorCapsule | Array<Franchisee>,
  offices: null | ErrorCapsule | Array<Office>,
  onSubmit: (values: { [string]: any }) => void,
  onSetRole: (role: string) => void,
}

function getRoleTitleByUser({ isFranchiseeManager, isLibraryEditor, isAdmin, franchiseeId }: User) {
  if (isAdmin) return 'Администратор';
  if (isFranchiseeManager) return franchiseeId ? 'Руководитель Франчайзи' : 'Контролер';
  if (isLibraryEditor) return 'Редактор библиотеки';
  return 'Резчик';
}

function transformValues(offices, role, values): User {
  switch (role) {
    case 'cutter': {
      const office = _find(offices, ({ id }) => id === values.officeId);
      return {
        ...values,
        franchiseeId: office.franchisee ? office.franchisee.id : null,
        isFranchiseeManager: false,
        isLibraryEditor: false,
        isAdmin: false,
      };
    }
    case 'franchisee': return {
      ...values,
      isFranchiseeManager: true,
      isLibraryEditor: false,
      isAdmin: false,
      officeId: null,
    };
    case 'libraryEditor': return {
      ...values,
      isFranchiseeManager: false,
      isLibraryEditor: true,
      isAdmin: false,
      franchiseeId: null,
      officeId: null,
    };
    case 'admin': return {
      ...values,
      isFranchiseeManager: false,
      isLibraryEditor: false,
      isAdmin: true,
      franchiseeId: null,
      officeId: null,
    };
    default:
      throw new UnexpectedCaseException();
  }
}

const combinedMetaModel = new CombinedMetaModel();
combinedMetaModel.add(userMetaModel);
combinedMetaModel.add(authMetaModel, ['password']);

export default function UserEditPageView({
  user,
  role,
  franchisees,
  offices,
  onSubmit,
  onSetRole,
}: UserEditPageViewProps) {
  const { t } = useTranslation();

  const loaded = analyseLoaded(user, franchisees, offices);
  if (!loaded) return <Loader />;
  if (loaded instanceof ErrorCapsule) return <LoadFailed error={loaded} />;

  const isCreating = user.id == null;

  const adjustedUser = {
    ...user,
    franchiseeId: user.franchiseeId || (franchisees.length ? franchisees[0].id : null),
    officeId: user.officeId || (offices.length ? offices[0].id : null),
  };

  return (
    <div className="user-edit">
      <h1>{isCreating ? 'Добавление пользователя' : `Изменение ${user.email}`}</h1>
      <DefaultForm
        form="userEdit"
        metaModel={combinedMetaModel}
        initialValues={adjustedUser}
        onSubmit={values => onSubmit(isCreating ? transformValues(offices, role, values) : values)}
      >
        <DefaultFormGlobalValidation />
        {isCreating
          ? (
            <>
              <div className="card mb-3">
                <div className="card-body d-flex">
                  <div className="w-50">
                    <p>Роль добавляемого:</p>
                    <div className="form-check mb-1">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={role === 'cutter'}
                          disabled={offices.length === 0}
                          onChange={e => onSetRole('cutter')}
                        />
                        Резчик
                      </label>
                    </div>
                    <div className="form-check mb-1">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={role === 'franchisee'}
                          disabled={franchisees.length === 0}
                          onChange={e => onSetRole('franchisee')}
                        />
                        Франчайзи / Контролер
                      </label>
                    </div>
                    <div className="form-check mb-1">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={role === 'libraryEditor'}
                          onChange={e => onSetRole('libraryEditor')}
                        />
                        Редактор библиотеки
                      </label>
                    </div>
                    <div className="form-check mb-1">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={role === 'admin'}
                          onChange={e => onSetRole('admin')}
                        />
                        Администратор
                      </label>
                    </div>
                  </div>
                  <div className="">
                    {(role === 'franchisee') && (
                      <DefaultFormDropDown
                        attribute="franchiseeId"
                        label="Организация"
                        options={[['', 'Свой офис'], ...franchisees.map(({ id, title }) => [id, title])]}
                      />
                    )}
                    {(role === 'cutter') && (
                      <DefaultFormDropDown
                        attribute="officeId"
                        options={offices.map(({ id, title, franchisee }) => [
                          id,
                          (franchisee ? franchisee.title : 'Свой офис') + ' - ' + title,
                        ])}
                      />
                    )}
                  </div>
                </div>
              </div>
              <DefaultFormText attribute="email" type="email" />
            </>
          )
          : (
            <>
              <div className="form-group">
                <label htmlFor="userEdit_role">Роль</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={
                    getRoleTitleByUser(user)
                    + (user.franchisee ? ' @ ' + user.franchisee.title : '')
                    + (user.office ? ' @ ' + user.office.title : '')
                  }
                  disabled
                />
              </div>
              <div className="form-group">
                <label htmlFor="userEdit_role">E-mail</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={user.email}
                  disabled
                />
              </div>
            </>
          )
        }

        <DefaultFormText attribute="password" type="password" autoComplete="new-password" />
        <DefaultFormText attribute="lastName" />
        <DefaultFormText attribute="firstName" />
        <DefaultFormText attribute="fathersName" />
        <DefaultFormText attribute="phone" />
        <DefaultFormText attribute="notes" />
        <DefaultFormFile attribute="avatar" hint={announceFileIsUploaded(user.avatar)} />
        <DefaultFormSubmit label={isCreating ? 'Добавить' : 'Изменить'} />
      </DefaultForm>
    </div>
  );
}
