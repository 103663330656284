// @flow
import React, { useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import _omit from 'lodash/omit';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import {
  getUser,
  createUser,
  updateUser,
  filterUserInput,
} from './model/User.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import UserEditPageView from './views/UserEditPageView';
import type { User } from './model/User.model';
import { getAllFranchisees, loadAllFranchisees } from '../org/redux/Franchisee.redux';
import { getAllOffices, loadAllOffices } from '../org/redux/Office.redux';

type UserEditState = {
  user: null | User | ErrorCapsule,
}

const userShape = `{
  id
  email
  firstName
  lastName
  fathersName
  phone
  notes
  avatar {
    id
    cover(width: 100, height: 100) { width height url }
  }
  isFranchiseeManager
  isLibraryEditor
  isAdmin
  franchiseeId
  franchisee {
    id
    title
    notes
  }
  officeId
  office {
    id
    franchiseeId
    title
    notes
  }
}`;

const initialState: UserEditState = {
  user: null,
};

const ACTION_SET_USER = 'users/userEdit/ACTION_SET_USER';

function setUser(user: null | User | ErrorCapsule) {
  return {
    type: ACTION_SET_USER,
    user,
  };
}

async function loadUser(id: string) {
  try {
    const user = await getUser(id, userShape);

    return setUser(user);
  } catch (err) {
    return setUser(new ErrorCapsule(err, () => [
      setUser(initialState.user),
      loadUser(id),
    ]));
  }
}

export async function saveUser(values) {
  const user = !values.id
    ? await createUser(
      { ...filterUserInput(values), franchiseeId: values.franchiseeId || null },
      userShape
    )
    : await updateUser(
      _omit(filterUserInput(values), ['franchiseeId', 'officeId', 'email']),
      userShape
    );

  return [
    setUser(user),
    redirect('/users/'),
  ];
}

export function userEditReducer(
  state: UserEditState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_USER:
      return {
        ...state,
        user: action.user,
      };

    default:
      return state;
  }
}

type Props = {
  id?: string,
};

function UserEditPage({ id }: Props) {
  const dispatch = useDispatch();
  const { user } = useSelector(({ users }) => users.userEdit);
  const franchisees = useSelector(getAllFranchisees);
  const offices = useSelector(getAllOffices);

  // Init redux state
  useEffect(() => {
    dispatch([
      ...(!franchisees ? [loadAllFranchisees()] : []),
      ...(!offices ? [loadAllOffices()] : []),
      ...(id ? [
        setUser(null),
        loadUser(id),
      ] : [
        setUser({}),
      ]),
    ]);
    return () => dispatch(setUser(initialState.user));
  }, []);

  // Init view state
  const [role, setRole] = useState('cutter');

  // Make role active
  let effectiveRole = role;
  if (Array.isArray(offices) && Array.isArray(franchisees)) {
    if (effectiveRole === 'cutter' && offices.length === 0) {
      effectiveRole = 'franchisee';
    }
    if (effectiveRole === 'franchisee' && franchisees.length === 0) {
      effectiveRole = 'admin';
    }
  }

  // Render
  return (
    <UserEditPageView
      user={user}
      role={effectiveRole}
      franchisees={franchisees}
      offices={offices}
      onSubmit={(values: User) => dispatch(saveUser(values))}
      onSetRole={setRole}
    />
  );
}

export function userEditPageRoutes(): Routes {
  return [
    {
      title: 'Добавление пользователя',
      path: '/add/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <UserEditPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
    {
      title: 'Изменение пользователя',
      path: '/edit/:id/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <UserEditPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
