// @flow
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { User } from '../model/User.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { useDialog } from '../../elements/Dialog';

export type UsersListPageViewProps = {
  isAdminUi: boolean,
  users: null | Array<User> | ErrorCapsule,
  onDeleteUser: (id: string) => void,
}

export default function UsersListPageView({
  isAdminUi,
  users,
  onDeleteUser,
}: UsersListPageViewProps) {
  const { t } = useTranslation();
  const { confirm } = useDialog();

  if (!users) return <Loader />;
  if (users instanceof ErrorCapsule) return <LoadFailed error={users} />;

  return (
    <div className="users-list">
      <Link to="/stats/logins/" className="float-right mt-3">история входов</Link>
      <h1>Пользователи</h1>

      {isAdminUi && (
        <div className="mb-1 text-right">
          <Link to="/users/add/" className="btn btn-primary btn-sm">добавить пользователя</Link>
        </div>
      )}
      <table className="table">
        <thead>
          <tr>
            {isAdminUi && <th>Франчайзи</th>}
            <th>Офис</th>
            <th>E-mail</th>
            <th>Последний вход</th>
            {isAdminUi && <th />}
          </tr>
        </thead>
        <tbody>
          {users.map(({
            id,
            email,
            isAdmin,
            isFranchiseeManager,
            isLibraryEditor,
            franchisee,
            office,
            lastLogin,
          }) => (
            <tr key={id} className="users-list__user">
              {isAdminUi && (
                <td>
                  {isAdmin
                    ? 'Администратор'
                    : (franchisee ? franchisee.title : 'Свой офис')
                  }
                </td>
              )}
              <td>{office ? office.title : (isFranchiseeManager ? 'Менеджер' : (isLibraryEditor ? 'Редактор' : null))}</td>
              <td>{email}</td>
              <td>{lastLogin ? moment.utc(lastLogin).format('DD.MM.YYYY HH:mm:ss') : '-'}</td>
              {isAdminUi && (
                <td className="align-middle w-25">
                  <Link to={`/users/edit/${id}/`} className="btn btn-primary btn-sm">
                    изменить
                  </Link>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={e => confirm({
                      renderBody: () => `Удаляем ${email}?`,
                      handler: () => onDeleteUser(id),
                    })}
                  >
                    удалить
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
