// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _find from 'lodash/find';
import { renderImageMeta } from '../../core/render/visualUtils';
import type { Variant } from '../../library/model/Variant.model';
import type { Cut } from '../../print/model/Cut.model';
import CutStatusWidget from '../../print/CutStatusWidget';
import type { Brand } from '../../library/model/Brand.model';
import type { Device } from '../../library/model/Device.model';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';

export type CutPageViewProps = {
  brand: Brand,
  device: Device,
  variants: Array<Variant>, // Never empty
  selectedVariantId: string, // Never empty, neither wrong
  canPrint: boolean,
  currentCut: ?Cut,
  onPrint: (variantId: string) => void,
  onSelectVariant: (id: string) => void,
}

export default function CutPageWorkView({
  brand,
  device,
  variants,
  selectedVariantId,
  canPrint,
  currentCut,
  onPrint,
  onSelectVariant,
}: CutPageViewProps) {
  const { t } = useTranslation();

  const selectedVariant: Variant = _find(variants, ({ id }) => id === selectedVariantId);

  return (
    <div className="cut">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/frontdesk/">Бренды</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/frontdesk/${brand.id}/`}>{brand.name}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">{device.name}</li>
        </ol>
      </nav>

      <div className="row">
        <div className="col-6">
          <h2>Выберите вариант резки</h2>
          <div className="row">
            <div className="col-7">
              <div
                className="nav flex-column nav-pills"
                role="tablist"
                aria-orientation="vertical"
              >
                {variants.map(({
                  id,
                  name,
                }) => (
                  <a
                    key={id}
                    className={'nav-link ' + (id === selectedVariantId ? 'active' : '')}
                    href={`#${id}`}
                    role="tab"
                    // aria-controls="v-pills-home"
                    aria-selected={id === selectedVariantId}
                    onClick={e => onSelectVariant(id)}
                  >
                    {name}
                  </a>
                ))}
              </div>
            </div>
            <div className="col-5">
              {selectedVariant.preview ? renderImageMeta(selectedVariant.preview.fit) : null}
            </div>
          </div>
        </div>
        <div className="col-6 border-left">
          <h2>Следуйте шагам</h2>
          <ol>
            <li>Выставить прижимные ролики согласно разметке.</li>
            <li>Вставить лист в плоттер, опустить рычаг и нажать на панели на кнопку 2.</li>
            <li>Нажать на кнопку &quot;Запустить рез&quot;, затем &quot;ОК&quot;.</li>
          </ol>
          {canPrint ? (
            <div className="d-flex align-items-center mb-4">
              <button type="button" className="btn btn-lg btn-primary" onClick={e => onPrint(selectedVariantId)}>
                Запустить рез
              </button>
              {currentCut && (
                <div className="ml-3">
                  {(currentCut instanceof ErrorCapsule)
                    ? <LoadFailed error={currentCut} />
                    : <CutStatusWidget id={currentCut.id} />
                  }
                </div>
              )}
            </div>
          ) : (
            <div className="alert alert-danger">Печать невозможна. Подключите плоттер.</div>
          )}
        </div>
      </div>
    </div>
  );
}
